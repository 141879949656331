import BlogsComp from "../../components/frontend-components/blogs/blogs-comp";


const BlogsPage = () => {
    return (
        <>
            <BlogsComp />
        </>
    )
}

export default BlogsPage;
