import BlogDetailsComp from "../../components/frontend-components/blogs/blog-details-comp";


const BlogsDetailsPage = () => {
    return (
        <>
            <BlogDetailsComp />
        </>
    )
}

export default BlogsDetailsPage;
